import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";

import { DesignsService } from "@core/api/designs.service";
import { SharedElementsService } from "@core/api/shared-elements.service";
import { CreativeEditorMode, CreativeLayer } from "@core/models/creative.types";
import { AccessService } from "@core/services/access.service";
import {
  DesignFormat,
  DesignFormatSpec,
} from "@core/services/creatives/creatives-edit-master.service";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CreativesLiveService } from "@core/services/creatives/creatives-live.service";
import { HotjarService } from "@core/services/hotjar.service";
import { Orientation } from "@core/utils/confect-math";

import { CSlideOverComponent } from "@theme/@confect/components/slide-over/slide-over.component";
import {
  ContextMenuService,
  ContextRef,
} from "@theme/@confect/services/confect-context-menu.service";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";
import {
  Pane,
  SplitSpec,
  SplitViewComponent,
} from "@theme/@confect/wrappers/split-view/split-view.component";

import { CreativesPreviewWindowComponent } from "@app/pages/creatives/creatives-preview-window/creatives-preview-window.component";

import { resolutions } from "../../creatives-resolutions";
import { CreativeError } from "../../types/creative-error";
import { CreativeEditPropertiesComponent } from "../creative-edit-properties/creative-edit-properties.component";
import { CreativesEditGridV3Component } from "../creatives-edit-grid-v3/creatives-edit-grid-v3.component";
import { CreativesEditTimelineComponent } from "../creatives-edit-timeline/creatives-edit-timeline.component";
import { ACADEMY_ARTICLES } from "./articles";

@Component({
  selector: "confect-creatives-editor",
  templateUrl: "./creatives-edit-editor.component.html",
  styleUrls: ["./creatives-edit-editor.component.scss"],
  standalone: false,
})
export class CreativesEditEditorComponent implements OnInit, AfterViewInit {
  public readonly CreativeEditorMode = CreativeEditorMode;

  @Input() editor: CreativesEditService = null;
  @Input() live: CreativesLiveService = null;

  _editorMode: CreativeEditorMode = CreativeEditorMode.IMAGE;
  @Input() set editorMode(to: CreativeEditorMode) {
    this._editorMode = to;
    this.setCurrentFormat();
  }
  @Input() videoSupport: boolean = false;
  @Input() allowModeSwitching: boolean = true;
  @Input() allowResolutionChange: boolean = true;
  @Input() showPreview: boolean = true;
  @Input() forceSingleProduct: boolean = false;
  @Input() loading: boolean = false;

  @Input() extraSettings = { minimalUser: true };
  @Input() helpEnabled = true;
  @Input() specs: any = {};
  _formats: DesignFormat[] | null = null;
  @Input() set formats(to: DesignFormat[]) {
    this._formats = to;
    this.setCurrentFormat();
  }

  @Input() defaultFormat: string;

  actualFormat: DesignFormat;
  _currentFormat: string;
  @Input() set currentFormat(to: string) {
    this._currentFormat = to;
    this.setCurrentFormat();
  }

  _formatSpecList: DesignFormatSpec[];
  @Input() set formatSpecList(to: DesignFormatSpec[]) {
    this._formatSpecList = JSON.parse(JSON.stringify(to));
    this.setCurrentFormat();
  }

  _formatSpec: Record<string, DesignFormatSpec> | null;
  @Input() set formatSpec(to: Record<string, DesignFormatSpec> | null) {
    this._formatSpec = JSON.parse(JSON.stringify(to));
    this.setCurrentFormat();
  }
  @Input() savedFormats: number = 1;

  @Input() selectedPreviewFilters = {};
  @Input() previewFilterOptions = [];
  @Input() selectedResolution = "1_1";
  @Input() currentLiveResolution: [number, number] = [500, 500];
  @Input() artBoardThumnails: Record<
    string,
    { css: string; resolution: [number, number] }
  >;

  @Output() selectedResolutionChange = new EventEmitter<any>();
  @Output() changeFormat = new EventEmitter<string>();
  @Output() addFormat = new EventEmitter<string>();

  dropDown: ContextRef;
  groupDropDown: ContextRef;
  multiDropDown: ContextRef;

  @ViewChild("grid", { static: false }) grid: CreativesEditGridV3Component;
  @ViewChild("canvas", { static: false }) canvas: ElementRef;
  @ViewChild("split") splitView: SplitViewComponent;
  @ViewChild("timeline") timeline: CreativesEditTimelineComponent;
  @ViewChild("previewWindow") previewWindow: TemplateRef<any>;
  @ViewChild("customResolutionModal") customResolutionModal: TemplateRef<any>;
  @ViewChild("noVideoSupportRes") noVideoSupportRes: TemplateRef<any>;
  @ViewChild("previewProducts", { static: true })
  previewProductsSlideover: CSlideOverComponent;
  @ViewChild("previewer") previewer: CreativesPreviewWindowComponent;
  @ViewChild("propertiesBox") properties: CreativeEditPropertiesComponent;
  @ViewChild("formatSettings") formatSettings: TemplateRef<any>;
  @ViewChild("copyToFormat") copyToFormatPrompt: TemplateRef<any>;

  @Output() resolutionChange = new EventEmitter<any>();

  @Output() editorModeChange = new EventEmitter<CreativeEditorMode>();
  @Output() selectedPreviewFiltersChange = new EventEmitter<any>();
  @Output() syncLayer = new EventEmitter<{ layer: string; key: string }>();
  @Output() updateFormatResolution = new EventEmitter<DesignFormatSpec>();
  @Output() reloadEditor = new EventEmitter<boolean>();
  @Output() overwriteLayer = new EventEmitter<{ from: string; to: string }>();
  @Output() deleteFormat = new EventEmitter<string>();
  @Output() copyLayerToFormat = new EventEmitter<{
    layer: CreativeLayer;
    format: string;
  }>();

  imageSpec: SplitSpec = {
    draggable: true,
    layout: {
      basis: 27,
      max: 35,
      min: 27,
      pane: Pane.Second,
      unit: "rem",
    },
    orientation: Orientation.Vertical,
    split: {
      first: {
        draggable: false,
        minimizable: true,
        layout: {
          basis: 0,
          max: 30,
          min: 0,
          pane: Pane.Second,
          unit: "%",
        },
        orientation: Orientation.Horizontal,
        splitID: "artBoard",
        split: {
          first: "canvas",
          second: "artBoard",
        },
      },
      second: {
        draggable: true,
        layout: {
          basis: 50,
          max: 80,
          min: 20,
          pane: Pane.First,
          unit: "%",
        },
        orientation: Orientation.Horizontal,
        split: {
          first: "layers",
          second: "properties",
        },
      },
    },
  };

  videoSpec: SplitSpec = {
    draggable: true,
    layout: {
      basis: 27,
      max: 35,
      min: 27,
      pane: Pane.Second,
      unit: "rem",
    },
    orientation: Orientation.Vertical,
    split: {
      first: {
        draggable: true,
        layout: {
          basis: 30,
          min: 30,
          max: 65,
          unit: "%",
          pane: Pane.Second,
        },
        orientation: Orientation.Horizontal,
        split: {
          first: "canvas",
          second: "timeline",
        },
      },
      second: "properties",
    },
  };

  academyArticles = ACADEMY_ARTICLES;

  videoAllowed: boolean = false;
  formatsAllowed: boolean = false;
  viewInit: boolean = false;

  multiDropDownOptions = [
    {
      display: "Group",
      function: (layers) => {
        const group = layers.filter((layer) => layer.type === "group");
        group.length > 0
          ? layers.forEach((layer) => {
              if (layer.type !== "group") {
                this.editor.addToGroup(layer, group[0]);
              }
            })
          : this.editor.groupSelectedLayers();
      },
      hide: (layers) => {
        return (
          layers.filter((layer) => {
            return layer.type === "group";
          }).length > 1
        );
      },
      icon: "folder_outlined",
    },
    {
      display: "Bring forward",
      function: (layers) => {
        this.editor.moveMultiSelect(layers, "up");
      },
      hide: (layers) => {
        const subLayers = layers.every(
          (layer) => layer.parent === layers[0].parent,
        );
        return !subLayers;
      },
      icon: "bring_forward",
    },
    {
      display: "Send backward",
      function: (layers) => {
        this.editor.moveMultiSelect(layers, "down");
      },
      hide: (layers) => {
        const subLayers = layers.every(
          (layer) => layer.parent === layers[0].parent,
        );
        return !subLayers;
      },
      icon: "send_backward",
    },
    {
      display: "Save as Element",
      function: (layers) => {
        const layerGroup = this.editor.groupLayers(layers);
        this.elementService.saveLayerGroup(
          layerGroup,
          this.designsService,
          this.popUpService,
        );
      },
      hide: (layers) =>
        !layers.every(
          (layer) => layer.parent == null && layer.type !== "group",
        ),
      icon: "save_outlined",
    },
  ];

  dropDownOptions = [
    {
      display: "Rename",
      function: (layer) => {
        this.editor.editLayer(layer.identifier, this.popUpService);
      },
      hide: (layer) => false,
      icon: "edit",
    },
    {
      display: "Duplicate",
      function: (layer) => {
        this.editor.duplicateLayer(layer.identifier);
      },
      hide: (layer) => false,
      disable: (layer) =>
        layer.config.grid_config[0].type === "product" &&
        this.forceSingleProduct,
      disableMsg: "You cannot duplicate a product layer",

      icon: "copy",
    },
    {
      display: "Bring forward",
      function: (layer) => {
        this.editor.moveLayer(layer, "up");
      },
      hide: (layer) => false,

      icon: "bring_forward",
    },
    {
      display: "Send backward",
      function: (layer) => {
        this.editor.moveLayer(layer, "down");
      },
      hide: (layer) => false,
      icon: "send_backward",
    },
    {
      display: "Copy layer to format",
      function: (layer) => {
        const layerCopy = JSON.parse(JSON.stringify(layer));
        this.openLayerToFormat(layerCopy);
      },
      hide: (layer) => !this.formatsAllowed,
      disable: (layer) =>
        layer.config.grid_config[0].type === "product" &&
        this.forceSingleProduct,
      disableMsg: "You cannot copy a product layer",
      icon: "copy",
    },
    {
      display: "Delete",
      function: (layer) => {
        this.editor.removeLayer(layer.identifier);
      },
      hide: (layer) => false,
      disable: (layer) =>
        layer.config.grid_config[0].type === "product" &&
        this.forceSingleProduct,
      disableMsg: "You cannot delete a product layer",
      icon: "delete_outlined",
    },
  ];

  groupDropDownOptions = [
    {
      display: "Rename",
      function: (layer) => {
        this.editor.editLayer(layer.identifier, this.popUpService);
      },
      hide: (layer) => false,

      icon: "edit",
    },
    {
      display: "Duplicate",
      function: (layer) => {
        this.editor.duplicateLayer(layer.identifier);
      },
      hide: (layer) => false,
      disable: (layer) => {
        return this.editor.layer.layers.some(
          (layer) => layer.config?.grid_config[0].type === "product",
        );
      },
      disableMsg:
        "You cannot duplicate a product layer. Remove product layer from group first.",
      icon: "copy",
    },
    {
      display: "Save as Element",
      function: (layer) => {
        this.elementService.saveLayerGroup(
          layer,
          this.designsService,
          this.popUpService,
        );
      },
      hide: (layer) => false,
      icon: "save_outlined",
    },
    {
      display: "Copy layer to format",
      function: (layer) => {
        const layerCopy = JSON.parse(JSON.stringify(layer));
        this.openLayerToFormat(layerCopy);
      },
      hide: (layer) => !this.formatsAllowed,
      disable: (layer) => {
        return this.editor.layer.layers.some(
          (layer) => layer.config?.grid_config[0].type === "product",
        );
      },
      disableMsg:
        "You cannot duplicate a product layer. Remove product layer from group first.",
      icon: "copy",
    },
    {
      display: "Bring forward",
      function: (layer) => {
        this.editor.moveLayer(layer, "up");
      },
      hide: (layer) => false,

      icon: "bring_forward",
    },
    {
      display: "Send backward",
      function: (layer) => {
        this.editor.moveLayer(layer, "down");
      },
      hide: (layer) => false,

      icon: "send_backward",
    },

    {
      display: "Delete",
      function: (layer) => {
        this.editor.removeLayer(layer.identifier);
      },
      hide: (layer) => false,
      disable: (layer) =>
        layer.layers.some((l) => l.config.grid_config[0].type === "product") &&
        this.forceSingleProduct,
      disableMsg:
        "You cannot delete a product layer. Remove product layer from group first.",
      icon: "delete_outlined",
    },
  ];

  filteredCount: number;
  totalCount: number;

  /* Zoom */
  maxZoom = 3.0;
  minZoom = 0.5;
  zoomFactor = 0.1;

  hideBalloon: boolean = false;
  balloonDebounce;

  currentZoom = 1;
  /* End zoom */

  allowSnap: boolean = true;

  scrollOffset: { x: number; y: number } = { x: 0, y: 0 };

  // Video render flags
  resolutions = resolutions;
  objectKeys = Object.keys;
  objectEntries = Object.entries;
  resOptions = this.objectEntries(this.resolutions).map(([key, value]) => {
    return {
      key: key,
      value: value.name,
    };
  });

  previewShow = false;

  currentPreviewResolution: [number, number] = [1080, 1080];

  previewFiltersChanged = false;

  raisedError: CreativeError = null;

  hasLicense: boolean = false;
  subscriptionV2: boolean = false;

  defaultFormatResolution: string;

  hasChangedResolution: boolean = false;

  constructor(
    private elementService: SharedElementsService,
    private popUpService: CPopupModalService,
    private designsService: DesignsService,
    private hj: HotjarService,
    private contextService: ContextMenuService,
    private accessService: AccessService,
    private router: Router,
  ) {
    this.videoAllowed = this.accessService.hasFeature("video");
    this.formatsAllowed = this.accessService.hasProPlus();
    this.hasLicense = this.accessService.current.account?.has_license === true;
    this.subscriptionV2 =
      this.accessService.current.company.subscription_v2 === true;
  }

  ngOnInit() {}
  ngAfterViewInit(): void {
    this.viewInit = true;
  }

  openLayerToFormat(layer: CreativeLayer) {
    this.popUpService.template({
      template: this.copyToFormatPrompt,
      showClose: false,
      extra: {
        layer: layer,
        currentFormat: this._currentFormat,
        formats: this._formats.map((format) => {
          const formatCopy = JSON.parse(JSON.stringify(format));
          formatCopy["display"] = this._formatSpec[format.key].display;
          return formatCopy;
        }),
      },
    });
  }

  setCurrentFormat() {
    if (
      this._currentFormat == null ||
      this._formats == null ||
      this._formatSpec == null ||
      this._formatSpecList == null
    ) {
      return;
    }
    this.actualFormat = this._formats.find(
      (format) => format.uuid === this._currentFormat,
    );

    if (this._editorMode === CreativeEditorMode.VIDEO) {
      this._formatSpecList = this._formatSpecList.filter(
        (formatSpec) => formatSpec.key !== "video",
      );
    }

    this._formatSpecList.forEach((formatSpec) => {
      this._formatSpec[formatSpec.key].hide = false;
    });

    this._formats.forEach((format) => {
      this._formatSpec[format.key].hide = true;
    });
  }

  rightClick(event, item) {
    this.groupDropDown?.close(event);
    this.dropDown?.close(event);
    this.multiDropDown?.close(event);

    if (this.editor.multiSelectedLayerIdentifiers.size > 0) {
      this.multiDropDown = this.contextService.open({
        options: this.multiDropDownOptions,
        event: event,
        item: item,
      });
      this.multiDropDown.afterClose.subscribe({
        next: () => {
          this.multiDropDown = null;
        },
      });

      return;
    }
    if (this.editor.layer.type === "group") {
      this.groupDropDown = this.contextService.open({
        options: this.groupDropDownOptions,
        event: event,
        item: item,
      });
      this.groupDropDown.afterClose.subscribe({
        next: () => {
          this.groupDropDown = null;
        },
      });

      return;
    }

    this.dropDown = this.contextService.open({
      options: this.dropDownOptions,
      event: event,
      item: item,
    });

    this.dropDown.afterClose.subscribe({
      next: () => {
        this.dropDown = null;
      },
    });
  }

  update() {
    this.editor.layerChangedSource.next(true);
  }

  scrollZoom(event: any) {
    event.preventDefault();

    const width = this.canvas.nativeElement.offsetWidth;
    const height = this.canvas.nativeElement.offsetHeight;

    const scaleByHeight =
      (height / this.currentLiveResolution[1]) * this.currentLiveResolution[0] <
      width;
    const innerResX = scaleByHeight
      ? (height / this.currentLiveResolution[1]) * this.currentLiveResolution[0]
      : width;
    const innerResY = scaleByHeight
      ? height
      : (width / this.currentLiveResolution[0]) * this.currentLiveResolution[1];

    const maxOffsetVertical =
      Math.max(innerResY * 0.7 * this.currentZoom - height + 50, 0) / 2;
    const maxOffsetHorizontal =
      Math.max(innerResX * 0.7 * this.currentZoom - width + 50, 0) / 2;

    this.scrollOffset.x = Math.max(
      Math.min(this.scrollOffset.x - event.deltaX / 10, maxOffsetHorizontal),
      -maxOffsetHorizontal,
    );
    this.scrollOffset.y = Math.max(
      Math.min(this.scrollOffset.y - event.deltaY / 10, maxOffsetVertical),
      -maxOffsetVertical,
    );

    if (event.ctrlKey || event.metaKey) {
      this.currentZoom = Math.max(
        Math.min(
          this.currentZoom - Math.max(Math.min(event.deltaY, 10), -10) / 50,
          this.maxZoom,
        ),
        this.minZoom,
      );
    }

    this.editor.zoomChangedSource.next(this.currentZoom);
  }

  changeMode(to: CreativeEditorMode) {
    this._editorMode = to;
    this._formatSpecList = this._formatSpecList.filter(
      (formatSpec) => formatSpec.key !== "video",
    );
    this.hj.event(
      this._editorMode === CreativeEditorMode.VIDEO
        ? "video_editor_loaded"
        : "image_editor_loaded",
    );
    this.editorModeChange.emit(this._editorMode);
    this.splitView.reload();
    this.live.checkLayers();
  }

  // Is everything loaded so that we can show editor?
  isLoaded() {
    return (
      this.specs != null && this.resolutions != null && this.editor != null
    );
  }

  screenSize() {
    return {
      width: this.canvas.nativeElement.clientWidth,
      height: this.canvas.nativeElement.clientHeight,
    };
  }

  _fitResolution(
    targetResolution: [number, number],
    widthDim: number,
    heightDim: number,
  ): [number, number] {
    if (widthDim > targetResolution[0] && heightDim > targetResolution[1]) {
      return targetResolution;
    }

    const f = Math.max(
      targetResolution[0] / widthDim,
      targetResolution[1] / heightDim,
    );

    return [
      Math.round(targetResolution[0] / f),
      Math.round(targetResolution[1] / f),
    ];
  }

  @HostListener("window:keydown", ["$event"])
  shortcutListener(event: KeyboardEvent) {
    const shift = event.shiftKey;
    const mod = event.ctrlKey || event.metaKey;
    const code = event.code;

    if (mod) {
      if (code === "KeyZ") {
        if (shift) {
          this.editor.history.redo();
        } else {
          this.editor.history.undo();
        }
        return;
      }

      if (code === "KeyD") {
        event.preventDefault();
        const isGroup = this.editor.layer.type === "group";
        const hasProductLayer =
          isGroup &&
          this.editor.layer.layers.some(
            (layer) => layer.config?.grid_config[0].type === "product",
          );
        if (
          this.editor.layer.config?.grid_config[0].type === "product" ||
          hasProductLayer
        ) {
          return;
        }
        this.editor.duplicateLayer(this.editor.layer.identifier);
      } else if (code === "KeyG") {
        event.preventDefault();
        this.editor.groupSelectedLayers();
      }
      return;
    }

    // Disable shortcuts on input focus
    const tn = document.activeElement.tagName;
    if (tn === "INPUT" || tn === "TEXTAREA") {
      return;
    }

    switch (code) {
      case "Delete":
      case "Backspace":
        if (
          this.editor.layer == null &&
          this.editor.multiSelectedLayerIdentifiers.size === 0
        ) {
          return;
        }
        if (
          (this.editor.layer.config?.grid_config[0].type === "product" ||
            this.editor.layer.layers?.some(
              (l) => l.config?.grid_config[0].type === "product",
            )) &&
          this.forceSingleProduct
        ) {
          return;
        }

        this.editor.removeActiveLayer();
        break;
    }
  }

  configurePreviewResolution() {
    const toResolution = this.resolutions[this.selectedResolution].size;
    if (this._editorMode === CreativeEditorMode.IMAGE) {
      this.currentPreviewResolution = this._fitResolution(
        toResolution,
        2000,
        2000,
      );
    } else {
      this.currentPreviewResolution = this._fitResolution(
        toResolution,
        512,
        512,
      );
    }
  }

  configureResolution() {
    const toResolution = this.resolutions[this.selectedResolution].size;

    this.currentLiveResolution = this._fitResolution(toResolution, 1280, 1000);
    this.configurePreviewResolution();

    if (this.live) {
      this.live.resolution = this.currentLiveResolution;
      this.live.emptyImages();
      this.live.invalidateLayers();
    }
  }
  updateFormat(toResolution: [number, number]) {
    if (this.selectedResolution === "video") {
      return;
    }
    if (this.selectedResolution === "custom") {
      this.updateFormatResolution.emit({
        key: "custom",
        display: "Custom",
        resolution: toResolution,
      });
    } else {
      this.updateFormatResolution.emit(
        this._formatSpec[this.selectedResolution],
      );
    }
  }
  openFormatSettings(key: string, index: number) {
    const chosenUUID = this._formats.find((format) => format.key === key).uuid;
    const main = chosenUUID === this.defaultFormat;
    const callback = () => {
      this.popUpService.template({
        template: this.formatSettings,
        showClose: false,
        extra: {
          main: main,
          index: index,
          display: this._formatSpec[key].display,
          format: chosenUUID,
          key: key,
          formats: this._formats.map((format) => {
            const formatCopy = JSON.parse(JSON.stringify(format));
            formatCopy["display"] = this._formatSpec[format.key].display;
            return formatCopy;
          }),
        },
      });
    };
    if (main && !this.hasChangedResolution) {
      this.popUpService
        .warning({
          title: "You are about to change resolution for the main format.",
          text: "Are you sure this is what you want? We recommend adding more formats rather than changing the main resolution.",
          confirmText: "I am sure",
        })
        .outputs["modalClosed"].asObservable()
        .subscribe({
          next: (res) => {
            if (res) {
              callback();
            }
          },
        });
      this.hasChangedResolution = true;
      return;
    }
    callback();
  }

  preview() {
    this.configurePreviewResolution();
    this.previewShow = true;
    this.popUpService
      .template({ template: this.previewWindow, showClose: false })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          this.previewShow = false;
        },
      });
  }

  productFiltersChanged() {
    this.live.previewFilters = this.selectedPreviewFilters;
    this.selectedPreviewFiltersChange.emit(this.selectedPreviewFilters);
    this.previewFiltersChanged = true;
  }

  applyCustomResolution(resolution: [number, number]) {
    resolutions[this.selectedResolution].size = [
      Number(resolution[0]),
      Number(resolution[1]),
    ];
    this.configureResolution();
    this.updateFormat(resolutions[this.selectedResolution].size);
  }

  setCustomResolution() {
    const modalRef = this.popUpService.template({
      template: this.customResolutionModal,
      extra: { size: resolutions[this.selectedResolution].size },
      showClose: false,
    });
    modalRef.outputs["modalClosed"].asObservable().subscribe({
      next: (res) => {
        this.reloadEditor.emit(true);
        this.live.invalidateLayers();
        this.live.checkLayers();
      },
    });
    modalRef.outputs["escaped"].asObservable().subscribe({
      next: (res) => {
        this.reloadEditor.emit(true);
        this.live.invalidateLayers();
        this.live.checkLayers();
      },
    });
  }

  resolutionChanged() {
    if (this.selectedResolution === "custom") {
      this.setCustomResolution();
    } else if (this.selectedResolution === "video") {
      const modalRef = this.popUpService.warning({
        title: "Switch to Video",
        text: "Once you switch to video, you will not be able to switch back. Are you sure this is what you want?",
        cancelText: "Cancel",
        confirmText: "Switch",
      });
      modalRef.outputs["modalClosed"].asObservable().subscribe({
        next: (res) => {
          if (res) {
            this.changeMode(CreativeEditorMode.VIDEO);
          }
          this.reloadEditor.emit(true);
          this.live.invalidateLayers();
          this.live.checkLayers();
        },
      });
      modalRef.outputs["escaped"].asObservable().subscribe({
        next: (res) => {
          this.reloadEditor.emit(true);
          this.live.invalidateLayers();
          this.live.checkLayers();
        },
      });
    } else {
      this.configureResolution();
      this.updateFormat(resolutions[this.selectedResolution].size);
      this.live.invalidateLayers();
      this.live.checkLayers();
    }
  }

  switchMainRes(key: string) {
    this.resolutionChanged();
  }

  onRenderError(error) {
    if (!error) {
      return;
    }
    this.raisedError = error;
    this.popUpService.error({
      title: "Error",
      text: error.message,
      autoCloseTimeout: 1500,
    });
  }

  reloadPreview() {
    setTimeout(() => {
      this.previewer.previewer?.reload();
    });
  }
  toPlans = () => {
    this.router.navigate(["/settings/billing"], {
      state: {
        tab: "plans",
      },
    });
  };
  toMarkets = () => {
    this.router.navigate(["/settings/markets"]);
  };
}
