@if (editor?.sequences?.length > 0) {
  <confect-split-view
    #split
    (specChange)="grid.setCanvas(); update()"
    [spec]="_editorMode === CreativeEditorMode.VIDEO ? videoSpec : imageSpec"
  >
    <ng-template
      split-caret-tmp
      let-switch="switch"
      let-split="split"
      let-default="default"
      let-minimized="minimized"
    >
      @switch (true) {
        @case (split === "artBoard" && minimized) {
          <div split-content [show]="artBoardThumnails != null">
            <div
              class="rounded-md flex items-center justify-center translate-x-2 -translate-y-2"
            >
              <confect-button
                tooltip
                tooltipPosition="above"
                tooltipText="1:1, 9:16, 4:5, and other formats"
                type="white"
                size="xlarge"
                class="group"
                (click)="switch()"
                ><confect-icon
                  icon="formats"
                  iconClass="group-hover:opacity-80 text-lg mr-1"
                  >Formats</confect-icon
                ></confect-button
              >
            </div>
          </div>
        }
        @default {
          <ng-template
            [ngTemplateOutlet]="default"
            [ngTemplateOutletContext]="{ switch: switch }"
          />
        }
      }
    </ng-template>
    <ng-template split-content-tmp let-view="view">
      <div split-content [show]="view === 'canvas'" class="relative pt-14">
        @if (view === "canvas" && !loading) {
          <div
            id="canvas"
            class="z-10 relative h-full w-full"
            [ngClass]="{
              'video-mode': _editorMode === CreativeEditorMode.VIDEO,
              'image-mode': _editorMode !== CreativeEditorMode.VIDEO,
            }"
            #canvas
            (wheel)="scrollZoom($event)"
          >
            <ngx-creatives-edit-grid-v3
              #grid
              [editor]="editor"
              [live]="live"
              [aspect]="selectedResolution"
              [resolution]="currentLiveResolution"
              [zoomFactor]="currentZoom"
              (context)="
                rightClick($event, editor.layer ?? editor.multiSelectedLayers())
              "
              [showBox]="dropDown?.open || groupDropDown?.open"
              [editorMode]="_editorMode"
              [time]="editor.selectedTime"
              [scrollOffset]="scrollOffset"
              [allowSnap]="allowSnap"
            />
          </div>
          <div #header class="absolute top-16 px-2 w-full">
            <div class="flex justify-between items-center"></div>
          </div>

          <div #footer class="absolute bottom-2 w-full px-2">
            <div class="flex items-center justify-between">
              <div>
                @if (_editorMode === CreativeEditorMode.VIDEO) {
                  <confect-select
                    class="w-40 cursor-pointer"
                    [options]="_formatSpecList"
                    bindLabel="display"
                    bindValue="key"
                    dropdownPosition="top"
                    [searchable]="false"
                    [(ngModel)]="selectedResolution"
                    (ngModelChange)="switchMainRes($event)"
                  />
                  @if (selectedResolution === "custom") {
                    <confect-icon-button
                      class="hover:opacity-80"
                      (click)="setCustomResolution()"
                      icon="settings_outlined"
                      iconClass="text-xl"
                    />
                  }
                }
              </div>
              <div class="flex items-center space-x-2">
                <confect-slider
                  class="w-60"
                  tooltip
                  tooltipText="Zoom canvas"
                  tooltipPosition="above"
                  [sliderInfo]="{
                    interval: { start: minZoom * 100, end: maxZoom * 100 },
                    stepSize: 1,
                    default: 100,
                  }"
                  [writable]="false"
                  [decimals]="0"
                  type="light"
                  [ngModel]="currentZoom * 100"
                  (ngModelChange)="
                    currentZoom = $event / 100; scrollOffset = { x: 0, y: 0 }
                  "
                />
              </div>
            </div>
          </div>
        }
      </div>
      <div split-content [show]="view === 'artBoard'">
        @if (view === "artBoard") {
          <confect-card
            class="h-full w-full z-[400] px-2 pb-2"
            [fullSize]="true"
          >
            <confect-card-body>
              <div class="w-full h-full">
                @if (
                  artBoardThumnails != null &&
                  _formatSpec != null &&
                  _editorMode !== CreativeEditorMode.VIDEO
                ) {
                  <div
                    class="w-full h-full flex items-center justify-start overflow-auto"
                  >
                    @for (
                      format of _formats;
                      track format.uuid;
                      let i = $index
                    ) {
                      <div
                        class="flex flex-col h-full aspect-square items-center justify-center gap-5"
                      >
                        <div
                          [style.height.%]="70"
                          [style.width.%]="
                            ((artBoardThumnails[format.uuid]?.resolution[0] ??
                              1) /
                              (artBoardThumnails[format.uuid]?.resolution[1] ??
                                1)) *
                            70
                          "
                          class="ring-2 ring-slate-300 rounded-md cursor-pointer hover:ring-primary bg-contain"
                          (click)="
                            _currentFormat !== format.uuid &&
                              changeFormat.emit(format.uuid);
                            editor.deselectLayer()
                          "
                          [style.background-image]="
                            artBoardThumnails[format.uuid]?.css | safe: 'style'
                          "
                        ></div>
                        <div
                          class="flex items-center justify-center gap-2 px-2 py-1 rounded-md"
                          [ngClass]="{
                            'bg-slate-100': format.uuid === _currentFormat,
                          }"
                        >
                          <div>{{ _formatSpec[format.key].display }}</div>
                          -
                          <div>
                            {{ resolutions[format.key].size[0] }}
                            x
                            {{ resolutions[format.key].size[1] }}
                          </div>
                          @if (format.uuid === defaultFormat) {
                            <span
                              class="inline-flex items-center rounded-lg bg-primary-50 px-2 py-1 text-xs font-medium text-primary-600 ring-1 ring-inset ring-primary-500/50"
                              >Main</span
                            >
                          }
                          @if (
                            format.uuid !== defaultFormat ||
                            _formats.length === 1
                          ) {
                            <confect-icon
                              (click)="openFormatSettings(format.key, i)"
                              icon="settings"
                              iconClass="text-slate-600 hover:opacity-60 cursor-pointer text-2xl"
                            />
                          }
                        </div>
                      </div>
                    }
                    <ng-template
                      #formatSettings
                      let-close="close"
                      let-extra="extra"
                    >
                      <div class="w-[350px]">
                        <div
                          class="w-full flex items-center justify-center text-lg font-semibold mb-5"
                        >
                          {{ extra.main ? "Main" : extra.display }} Format
                          Settings
                        </div>
                        <div class="w-full flex flex-col gap-2">
                          <div class="w-full grid grid-cols-2">
                            @if (!extra.main) {
                              <div class="flex items-center">
                                Copy design to other format
                              </div>
                              <div class="flex items-center justify-end gap-2">
                                @for (
                                  format of extra.formats;
                                  track format.key
                                ) {
                                  @if (format.uuid !== extra.format) {
                                    <div
                                      class="w-10 h-10 flex items-center justify-center bg-white rounded-md ring-2 ring-inset ring-slate-300 hover:ring-primary cursor-pointer"
                                      (click)="
                                        overwriteLayer.emit({
                                          to: extra.format,
                                          from: format.uuid,
                                        });
                                        close()
                                      "
                                    >
                                      {{ format.display }}
                                    </div>
                                  }
                                }
                              </div>
                            } @else {
                              <div class="flex items-center">
                                Change Resolution
                              </div>
                              <div class="flex w-full items-center gap-2">
                                <confect-select
                                  class="cursor-pointer w-full"
                                  [options]="_formatSpecList"
                                  bindLabel="display"
                                  bindValue="key"
                                  dropdownPosition="bottom"
                                  [searchable]="false"
                                  [(ngModel)]="selectedResolution"
                                  (ngModelChange)="
                                    switchMainRes($event); close()
                                  "
                                />
                                @if (selectedResolution === "custom") {
                                  <confect-icon-button
                                    class="hover:opacity-80"
                                    (click)="setCustomResolution()"
                                    icon="settings_outlined"
                                    iconClass="text-xl"
                                  />
                                }
                              </div>
                            }
                          </div>
                          @if (extra.index >= savedFormats) {
                            <div class="grid grid-cols-2">
                              <div class="flex items-center">Delete format</div>
                              <div class="flex items-center justify-end">
                                <confect-button
                                  type="danger"
                                  (click)="
                                    deleteFormat.emit(extra.key); close()
                                  "
                                  >Delete</confect-button
                                >
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </ng-template>
                    @if (
                      _formats.length < _formatSpecList.length - 2 &&
                      selectedResolution !== "custom" &&
                      this.formatsAllowed
                    ) {
                      <div
                        class="flex h-full aspect-square items-center justify-center gap-5"
                      >
                        <div
                          class="w-[50%] h-[50%] flex items-center justify-center rounded-md group bg-slate-100 hover:bg-slate-200 cursor-pointer"
                          dialogBox
                          dialogPosition="above"
                          dialogAlign="left"
                          [dialogTemplate]="formatSelection"
                        >
                          <confect-icon
                            icon="add"
                            iconClass="text-2xl text-slate-400 group-hover:text-slate-500"
                          />
                        </div>
                      </div>
                      <ng-template #formatSelection let-close="close">
                        <div class="w-52 flex flex-col">
                          @for (format of _formatSpecList; track format.key) {
                            @if (
                              format.key !== "video" &&
                              format.key !== "custom" &&
                              !_formatSpec[format.key].hide
                            ) {
                              <div
                                class="w-full px-3 py-2 cursor-pointer hover:bg-slate-50"
                                (click)="addFormat.emit(format.key); close()"
                              >
                                {{ format.display }}
                              </div>
                            }
                          }
                        </div>
                      </ng-template>
                    }
                  </div>
                }
              </div>
            </confect-card-body>
          </confect-card>
        }
      </div>
      <div
        split-content
        [show]="view === 'properties'"
        class="pr-2 pl-1 pb-2"
        [ngClass]="{
          'pt-16': _editorMode === CreativeEditorMode.VIDEO,
          'pt-1': _editorMode !== CreativeEditorMode.VIDEO,
        }"
      >
        @if (view === "properties") {
          <ngx-creative-edit-properties
            class="w-full h-full z-[400]"
            #propertiesBox
            [editorMode]="_editorMode"
            [editor]="editor"
            [live]="live"
            [specs]="specs"
            [helpEnabled]="helpEnabled"
            [extraSettings]="extraSettings"
            [(selectedPreviewFilters)]="selectedPreviewFilters"
            (selectedPreviewFiltersChange)="
              selectedPreviewFiltersChange.emit($event)
            "
            [previewFilterOptions]="previewFilterOptions"
            [(allowSnap)]="allowSnap"
            [showPreview]="showPreview"
            [mainFormat]="_currentFormat === defaultFormat"
            [format]="actualFormat"
            (syncLayer)="syncLayer.emit($event)"
          />
        }
      </div>
      <div
        split-content
        [show]="view === 'layers'"
        class="pr-2 pl-1 pt-16 pb-1"
      >
        @if (view === "layers") {
          <confect-card
            class="timeline-container image-mode shadow overflow-y-auto w-full h-full"
            [fullSize]="true"
          >
            <confect-card-body>
              <ngx-creatives-edit-timeline
                [editor]="editor"
                [live]="live"
                [specs]="specs"
                [showTimeline]="false"
                [raisedError]="raisedError"
                [allowProductActions]="!forceSingleProduct"
                (context)="rightClick($event.event, $event.layer)"
              />
            </confect-card-body>
          </confect-card>
        }
      </div>
      <div
        split-content
        [show]="view === 'timeline'"
        class="pl-2 pb-2 pr-1 pt-1"
      >
        @if (view === "timeline") {
          <confect-card
            class="timeline-container video-mode shadow overflow-y-auto w-full h-full"
            [withoutPadding]="true"
            [fullSize]="true"
          >
            <confect-card-body>
              <div class="w-full h-full">
                <ngx-creatives-edit-timeline
                  #timeline
                  [editor]="editor"
                  [live]="live"
                  [specs]="specs"
                  [showTimeline]="true"
                  [raisedError]="raisedError"
                  [allowProductActions]="!forceSingleProduct"
                  (context)="rightClick($event.event, $event.layer)"
                />
              </div>
            </confect-card-body>
          </confect-card>
        }
      </div>
    </ng-template>
  </confect-split-view>
}

<ng-template #previewWindow let-close="close">
  @if (previewShow) {
    <div class="h-[70vh] w-[80vh]">
      <ngx-creatives-preview-window
        #previewer
        [editor]="editor"
        [selectedFilters]="selectedPreviewFilters"
        [mode]="_editorMode"
        [resolution]="currentPreviewResolution"
        (renderError)="onRenderError($event)"
        (closeWindow)="close()"
      />
    </div>
  }
</ng-template>

<!-- <confect-slide-over
  title="Preview products"
  #previewProducts
  (cancel)="resetProductFilter()"
>
  <div class="flex flex-col items-center space-y-4 mx-4">
    <ngx-product-filter
      style="max-width: 100%"
      [filterOptions]="previewFilterOptions"
      [(selectedFilters)]="selectedPreviewFilters"
      (selectedFiltersChange)="productFiltersChanged()"
    />

    <confect-button
      class="w-full"
      [block]="true"
      type="white"
      size="large"
      (click)="resetProductFilter()"
      >Reset</confect-button
    >

    <confect-button
      class="w-full"
      [block]="true"
      size="large"
      (click)="applyProductFilter()"
      >Apply</confect-button
    >
  </div>
</confect-slide-over> -->
<ng-template #customResolutionModal let-extra="extra" let-close="close">
  <div class="text-center">
    <div class="grid grid-cols-2 gap-8">
      <div class="text-center">Width</div>
      <div class="text-center">Height</div>
      <confect-input [(ngModel)]="extra.size[0]" type="number" />
      <confect-input [(ngModel)]="extra.size[1]" type="number" />
    </div>
  </div>
  <div class="flex items-center justify-center space-x-8 mt-5">
    <confect-button (click)="close(false)" type="white">Cancel</confect-button>
    <confect-button
      (click)="applyCustomResolution(extra.size); close(true)"
      type="secondary"
      >Apply</confect-button
    >
  </div>
</ng-template>

<ng-template #noVideoSupport let-extra="extra" let-close="close">
  <div class="text-white w-52">
    @if (hasLicense) {
      Upgrade to the <span class="font-bold">Pro</span> plan or better to get
      access to video designs and catalogs.
      <div class="w-full flex justify-center mt-5">
        <confect-button type="white" (click)="extra.plans()">
          See Plans
        </confect-button>
      </div>
    } @else {
      This market is inactive and needs to be assigned a license for this
      feature to be available.
      <div class="w-full flex justify-center mt-5">
        <confect-button type="white" (click)="extra.markets()">
          Go to Markets
        </confect-button>
      </div>
    }
  </div>
</ng-template>

<ng-template #noVideoSupportRes let-extra="extra" let-close="close">
  <div class="text-white w-52">
    @if (hasLicense) {
      Upgrade to the <span class="font-bold">Pro</span> plan or better to get
      access to video designs and catalogs.
    } @else {
      This market is inactive and needs to be assigned a license for this
      feature to be available.
    }
  </div>
</ng-template>

<ng-template #copyToFormat let-extra="extra" let-close="close">
  <div class="w-96"></div>
  <div
    class="w-full flex items-center justify-center text-lg font-semibold mb-5"
  >
    Copy layer to another format.
  </div>
  <div class="w-full grid grid-cols-2">
    <div class="flex items-center">Format to copy to</div>
    <div class="flex items-center justify-end gap-2">
      @for (format of extra.formats; track format.key) {
        @if (format.uuid !== extra.currentFormat) {
          <div
            class="w-10 h-10 flex items-center justify-center bg-white rounded-md ring-2 ring-inset ring-slate-300 hover:ring-primary cursor-pointer"
            (click)="
              copyLayerToFormat.emit({
                layer: extra.layer,
                format: format.uuid,
              });
              close()
            "
          >
            {{ format.display }}
          </div>
        }
      }
    </div>
  </div>
</ng-template>
